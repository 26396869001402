import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';
import React, { FC } from 'react';
import { IIconItemComponentProps } from './models';
import './IconItem.scss';

const IconItem: FC<IIconItemComponentProps> = ({ iconImage, iconAlt, description }) => (
  <div className="icon-item">
    <div className="icon-item__image-wrapper">
      {iconImage?.childImageSharp ? (
        <GatsbyImage fluid={iconImage?.childImageSharp.fluid} alt={iconAlt} />
      ) : null}
    </div>
    <DangerouslySetInnerHtml className="icon-item__description" html={description} />
  </div>
);

export default IconItem;
