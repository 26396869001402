import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';

import { ILowerSectionBannerComponentProps } from 'components/LowerSectionBanner/models';

import './LowerSectionBanner.scss';

const LowerSectionBanner: FC<ILowerSectionBannerComponentProps> = ({
  lowerSectionBanner: [
    {
      properties: { title, image, imageAlt, description, brandLogo, brandLogoAlt },
    },
  ],
}) => {
  return (
    <div className="lower-section-banner" data-testid="lower-section-banner">
      <DangerouslySetInnerHtml className="title-base lower-section-banner__title" html={title} />
      {image?.childImageSharp ? (
        <div className="lower-section-banner__image-wrapper">
          <GatsbyImage fluid={image?.childImageSharp.fluid} alt={imageAlt} />
        </div>
      ) : null}
      <DangerouslySetInnerHtml
        className="title-base lower-section-banner__title"
        html={description}
      />

      {brandLogo?.childImageSharp ? (
        <div className="lower-section-banner__brand-logo-wrapper">
          <GatsbyImage fluid={brandLogo.childImageSharp.fluid} alt={brandLogoAlt} />
        </div>
      ) : null}
    </div>
  );
};

export default LowerSectionBanner;

export const query = graphql`
  fragment FragmentLowerSectionBanner on LowerSectionBannerTypes {
    properties {
      title
      image {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageAlt
      description
      brandLogo {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      brandLogoAlt
    }
  }
`;
