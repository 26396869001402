import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, DangerouslySetInnerHtml, Layout } from 'layout';
import LPHeroBanner from 'components/LPBanner';
import IconsSection from 'components/IconsSection';
import LowerSectionBanner from 'components/LowerSectionBanner';
import { SizeAndFitPageComponentProps } from './models';
import './SizeAndFitPageMain.scss';

const SizeAndFitPage: FC<SizeAndFitPageComponentProps> = ({
  data: {
    umbracoSizeAndFit: {
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      defaultCompositions,
      banner: [
        {
          properties: {
            firstTitle,
            secondTitle,
            imageBanner,
            imageBannerAlt,
            brandLogo,
            brandLogoAlt,
          },
        },
      ],
      url,
      title,
      mainDescription,
      iconsSection,
      iconsDescription,
      lowerSectionBanner,
    },
  },
}) => (
  <Layout
    defaultCompositions={defaultCompositions}
    seo={{
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
    }}
    className="size-and-fit-page"
  >
    <LPHeroBanner
      {...{
        firstTitle,
        secondTitle,
        imageBanner,
        imageBannerAlt,
        brandLogo,
        brandLogoAlt,
        inFirstViewport: true,
        url,
      }}
    />

    <Container className="size-and-fit-page__content">
      <DangerouslySetInnerHtml className="title-base content__title" html={title} />

      <p className="description">{mainDescription}</p>

      <IconsSection {...{ iconsSection }} />

      <p className="description description--wide">{iconsDescription}</p>

      <LowerSectionBanner {...{ lowerSectionBanner }} />
    </Container>
  </Layout>
);

export const query = graphql`
  query SizeAndFitPage($url: String = "") {
    umbracoSizeAndFit(url: { eq: $url }) {
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      banner {
        ...FragmentLPHeroBanner
      }
      title
      mainDescription
      iconsSection {
        ...FragmentIconsSection
      }
      iconsDescription
      lowerSectionBanner {
        ...FragmentLowerSectionBanner
      }
    }
  }
`;

export default SizeAndFitPage;
