import React, { FC } from 'react';
import { graphql } from 'gatsby';
import GatsbyImage from 'common/GatsbyImage';
import { Container } from 'layout';
import Breadcrumbs from 'gatsby-theme-husky/src/components/Breadcrumbs';
import LPHeroBannerTextSection from './LPHeroBannerTextSection';

import { LPHeroBannerComponentProps } from './models';
import './LPHeroBanner.scss';

const LPHeroBanner: FC<LPHeroBannerComponentProps> = ({
  firstTitle,
  secondTitle,
  imageBanner,
  imageBannerAlt,
  brandLogo,
  brandLogoAlt,
  inFirstViewport,
  url,
}) => (
  <Container fluid>
    <div className="lp-hero-wrapper" data-testid="lp-hero-wrapper">
      <div className="lp-hero-banner">
        {url ? <Breadcrumbs light url={url} className="banner__breadcrumbs" /> : null}
        {imageBanner?.childImageSharp ? (
          <div className="lp-hero-banner__image-wrapper">
            <GatsbyImage
              isLazyLoading={!inFirstViewport}
              fluid={imageBanner.childImageSharp.fluid}
              alt={imageBannerAlt}
              fadeIn={!inFirstViewport}
            />
          </div>
        ) : null}

        <LPHeroBannerTextSection
          {...{ firstTitle, secondTitle, brandLogo, brandLogoAlt, inFirstViewport }}
        />
      </div>
    </div>
  </Container>
);

export const query = graphql`
  fragment FragmentLPHeroBanner on LandingPageBannerTypes {
    properties {
      imageBanner {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageBannerAlt
      ...FragmentLPHeroBannerTextSection
    }
  }
`;

export default LPHeroBanner;
