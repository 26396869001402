import React, { FC } from 'react';

import { IIconsSectionComponentProps } from 'components/IconsSection/models';
import './IconsSection.scss';
import { graphql } from 'gatsby';
import IconItem from './IconItem';

const IconsSection: FC<IIconsSectionComponentProps> = ({ iconsSection }) => (
  <div className="icons-section" data-testid="icons-section">
    {iconsSection.map(({ properties: { iconAlt, iconImage, description } }) => (
      <IconItem key={iconAlt} {...{ iconAlt, iconImage, description }} />
    ))}
  </div>
);

export default IconsSection;

export const query = graphql`
  fragment FragmentIconsSection on IconsSectionTypes {
    properties {
      iconImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconAlt
      description
    }
  }
`;
