import GatsbyImage from 'common/GatsbyImage';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { LPHeroBannerTextSectionComponentProps } from './models';

const LPHeroBannerTextSection: FC<LPHeroBannerTextSectionComponentProps> = ({
  firstTitle,
  secondTitle,
  brandLogo,
  brandLogoAlt,
  inFirstViewport,
}) => {
  return (
    <div className="lp-hero-banner__text-section">
      <h1 className="lp-hero-banner__text-section__first-title">{firstTitle}</h1>
      <h2 className="lp-hero-banner__text-section__second-title">{secondTitle}</h2>
      <div className="lp-hero-banner__text-section__brand-logo-image">
        {brandLogo?.childImageSharp ? (
          <GatsbyImage
            isLazyLoading={!inFirstViewport}
            fluid={brandLogo.childImageSharp.fluid}
            alt={brandLogoAlt}
            fadeIn={!inFirstViewport}
          />
        ) : null}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentLPHeroBannerTextSection on LandingPageBannerProperties {
    firstTitle
    secondTitle
    brandLogo {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    brandLogoAlt
  }
`;

export default LPHeroBannerTextSection;
